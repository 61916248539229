import React from 'react'

import './ScrollTo.scss';

const ScrollTo = (props) => {
    function scrollHandler(e) {
        e.preventDefault()
        const toScroll = window.innerHeight

        window.scrollTo({
            left: 0,
            top: toScroll - 140,
            behavior: 'smooth'
        })
    }

    return (
        <button className="scrollTo" onClick={scrollHandler}>
            { props.children }
            <svg width="59" height="36" viewBox="0 0 59 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.28565 2.06274L1.25195 7.21106L29.4066 35.8159L57.5611 7.18214L52.5275 2.06274L29.4066 25.5772L6.28565 2.06274Z" />
            </svg>
        </button>
    )
}

export default ScrollTo