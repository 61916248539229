import './App.css'
import { BrowserRouter } from 'react-router-dom'
import Router from './route/Router'

import Header from 'modules/Header'
import Footer from 'modules/Footer'
import ScrollToTop from 'components/ScrollToTop'

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<div className="App">
				<Header />
				<Router basename={'/bigetnouf'} />
				<Footer />
			</div>
		</BrowserRouter>
	)
}

export default App
