import { React, useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'

// https://greensock.com/react-basics
// https://greensock.com/react-advanced

const HeroScene03 = (props) => {
    const scene = useRef()
    const tl = useRef()

    useLayoutEffect(() => {
        gsap.context(() => {
            tl.current = gsap
                .timeline()
                .to('.shape2', { x: -1000 * props.ratio }, 0.00)
                .to('.shape1', { x: -1000 * props.ratio }, 0.10)
                .to('.shape0', { x: -1000 * props.ratio }, 0.15)
        }, scene)
    }, [props.ratio])

    return (
        <svg ref={scene} className="hero__scene" width="1920" height="1080" viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
            {
                Array.from({ length: 3 }, (_, i) => {
                    return (
                        <g key={i} transform={`translate(${i * -104} 0)`}>
                            <path className={`shape shape${i}`} d="M59.7581 -59.5827C33.2877 -54.7268 -19.4126 -49.169 -57.3568 -47.2363C-109.533 -44.5824 -128.153 -41.9189 -133.765 -36.313C-141.673 -28.4138 -140.821 47.66 -130.151 286.516C-123.721 430.38 -124.722 628.634 -132.735 798.546C-141.538 985.281 -141.985 1071.06 -134.198 1078.84C-129.948 1083.09 -88.1251 1084.61 24.0375 1084.61C155.687 1084.61 177.335 1083.6 182.668 1077.18C189.025 1069.53 188.968 1061.88 179.328 624.264C176.604 500.718 177.143 478.847 182.913 478.967C190.594 479.121 220.251 517.213 465.06 841.36C569.252 979.324 644.736 1074.99 652.759 1079.25C663.574 1084.99 690.737 1086.43 792.894 1086.69C912.983 1087 919.976 1086.52 926.449 1077.68C932.196 1069.84 933.269 1016.3 933.178 741.137C933.11 523.444 935.772 340.594 941.138 194.841C945.575 74.3481 947.809 -29.8032 946.1 -36.6062C944.391 -43.4045 939.704 -50.2315 935.685 -51.77C931.662 -53.3133 861.799 -52.1883 780.429 -49.2748C673.127 -45.4334 630.668 -42.2842 625.875 -37.8226C617.409 -29.9474 618.088 73.2567 627.8 255.265C634.971 389.6 637.069 539.671 631.968 552.955C630.36 557.137 626.452 560.561 623.285 560.561C609.911 560.561 585.957 529.416 336.731 187.956C246.019 63.6748 165.12 -45.0439 156.948 -53.6354C139.362 -72.1358 130.973 -72.6503 59.7581 -59.5827Z" />
                        </g>
                    )
                })
            }
        </svg>
    )
}

export default HeroScene03