import { React, useState } from 'react'
import { Link } from 'react-router-dom'

import HeroScene01 from 'components/HeroScene/HeroScene01'
import HeroScene02 from 'components/HeroScene/HeroScene02'
import HeroScene03 from 'components/HeroScene/HeroScene03'
import HeroScene04 from 'components/HeroScene/HeroScene04'
import HeroScene05 from 'components/HeroScene/HeroScene05'
import HeroScene06 from 'components/HeroScene/HeroScene06'
import Logo from 'components/Logo'
import ScrollTo from 'components/ScrollTo'

import './Hero.scss';


const Hero = (props) => {
    const scene = (props.scene !== undefined) ? props.scene : 'small'

    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const [ratio, setRatio] = useState(0)
    const [hidden, setHidden] = useState(false)
    const [opacity, setOpacity] = useState(1)

    window.addEventListener('scroll', e => {
        const ratio = Math.min(1, Math.round((window.scrollY / (window.innerHeight / 2)) * 100) / 100)
        setRatio(ratio)
        setOpacity(1 - ratio)
        setHidden(ratio !== 0)
    })

    window.addEventListener('resize', e => {
        setInnerWidth(window.innerWidth)
    })

    if (scene === 1 || innerWidth > 768) {          
        return (
            <section className={`hero ${hidden ? 'hidden' : ''} ${props.className || ''}`}>
                <div className={`hero__container hero__position_${scene}`}>
                    {
                        (scene === 1) && (
                            <Link to={`${process.env.PUBLIC_URL}/`} className="hero__logo_homepage" style={{ opacity: opacity }}>
                                <Logo version="4" size="huge" complete={true} aligned={false} />
                            </Link>
                        )
                    }
                    {
                        (scene !== 1) && (
                            <div className="hero__logo" style={{ opacity: opacity }}>
                                <Logo version="0" size="smaller" complete={true} aligned={true} />
                            </div>
                        )
                    }
    
                    <h1
                        className={`bigger title position-${scene}`}
                        style={{ opacity: opacity }}
                    >
                        { props.children }
                    </h1>
    
                    { (scene === 1) && <HeroScene01 ratio={ratio} /> }
                    { (scene === 2) && <HeroScene02 ratio={ratio} /> }
                    { (scene === 3) && <HeroScene03 ratio={ratio} /> }
                    { (scene === 4) && <HeroScene04 ratio={ratio} /> }
                    { (scene === 5) && <HeroScene05 ratio={ratio} /> }
                    { (scene === 6) && <HeroScene06 ratio={ratio} /> }
    
                    {
                        (props.scrollTo !== undefined) && (
                            <div style={{ opacity: opacity }}>
                                <ScrollTo>{ props.scrollTo }</ScrollTo>
                            </div>
                        )
                    }
                </div>
            </section>
        )
    }
}

export default Hero