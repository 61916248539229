import React from 'react'

import './Icon.scss';

const Icon = (props) => {
    const version = (props.version !== undefined) ? props.version : 'mobile'
    const size = (props.size !== undefined) ? props.size : 'small'

    return (
        <span className={`icon ${size} ${props.className || ''}`}>
            {
                // Mobile
                (version === 'mobile') && (
                    <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.207 4.80909C30.7031 1.17628 26.7383 -0.757315 22.9492 0.277841L5.76172 4.96534C2.36328 5.90284 0 8.98878 0 12.5044C0 60.8247 39.1797 100.004 87.5 100.004C91.0156 100.004 94.1016 97.6411 95.0391 94.2427L99.7266 77.0552C100.762 73.2661 98.8281 69.3013 95.1953 67.7974L76.4453 59.9849C73.2617 58.6567 69.5703 59.5747 67.4024 62.2505L59.5117 71.8794C45.7617 65.3755 34.6289 54.2427 28.125 40.4927L37.7539 32.6216C40.4297 30.4341 41.3477 26.7622 40.0195 23.5786L32.207 4.82862V4.80909Z" />
                    </svg>
                )
            }
            
            {
                // Link
                (version === 'link') && (
                    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.2309 51.9448C-2.11798 42.5541 -2.11798 27.3463 7.2309 17.9557C15.5043 9.6454 28.5431 8.56506 38.0574 15.3961L38.3222 15.5789C40.7049 17.2909 41.2509 20.615 39.5466 22.9917C37.8423 25.3685 34.533 25.9336 32.1668 24.2216L31.902 24.0388C26.5905 20.2327 19.3265 20.831 14.7266 25.4682C9.51435 30.7037 9.51435 39.1802 14.7266 44.4156L33.292 63.0972C38.5042 68.3326 46.943 68.3326 52.1552 63.0972C56.7717 58.46 57.3674 51.1636 53.5782 45.845L53.3962 45.5791C51.6919 43.1857 52.2545 39.8616 54.6207 38.1663C56.9868 36.471 60.3127 37.0195 62.0005 39.3962L62.1825 39.6622C68.9997 49.2024 67.9242 62.2994 59.6509 70.6097C50.302 80.0003 35.1617 80.0003 25.8128 70.6097L7.2309 51.9448ZM93.2076 48.0555C102.556 57.4462 102.556 72.654 93.2076 82.0446C84.9342 90.3549 71.8954 91.4352 62.3811 84.6042L62.1163 84.4214C59.7336 82.7094 59.1875 79.3853 60.8919 77.0086C62.5962 74.6318 65.9055 74.0667 68.2717 75.7787L68.5364 75.9615C73.8479 79.7676 81.1119 79.1693 85.7119 74.5321C90.9241 69.28 90.9241 60.8035 85.7119 55.568L67.1465 36.9031C61.9343 31.6677 53.4955 31.6677 48.2833 36.9031C43.6667 41.5403 43.0711 48.8367 46.8603 54.1719L47.0423 54.4379C48.7466 56.8312 48.184 60.1553 45.8178 61.8506C43.4516 63.5459 40.1257 62.9974 38.438 60.6207L38.256 60.3548C31.4387 50.7979 32.5143 37.7009 40.7876 29.3906C50.1365 20 65.2767 20 74.6256 29.3906L93.2076 48.0555Z" />
                    </svg>
                )
            }

            {
                // Comment
                (version === 'comment') && (
                    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.5939 76.4095C27.1128 74.574 30.3737 74.1054 33.3026 75.1598C38.4771 77.0343 44.2764 78.1083 50.4467 78.1083C74.796 78.1083 91.0615 62.3896 91.0615 46.8662C91.0615 31.3428 74.796 15.6241 50.4467 15.6241C26.0974 15.6241 9.83203 31.3428 9.83203 46.8662C9.83203 53.1146 12.2533 59.1287 16.8029 64.2837C18.4822 66.1777 19.3023 68.6771 19.107 71.2155C18.8337 74.7498 17.994 77.9911 16.9005 80.8615C20.22 79.3189 22.9732 77.6006 24.5939 76.429V76.4095ZM4.59898 84.3372C4.95045 83.81 5.2824 83.2827 5.59482 82.7555C7.54745 79.5142 9.40245 75.2574 9.77345 70.4735C3.91555 63.815 0.459399 55.6725 0.459399 46.8662C0.459399 24.4305 22.8365 6.25146 50.4467 6.25146C78.0569 6.25146 100.434 24.4305 100.434 46.8662C100.434 69.3019 78.0569 87.4809 50.4467 87.4809C43.2025 87.4809 36.3292 86.2312 30.1199 83.9857C27.7962 85.6845 24.0081 88.0081 19.5171 89.9607C16.5686 91.2495 13.2101 92.4211 9.73439 93.1045C9.57818 93.1435 9.42197 93.1631 9.26576 93.2021C8.4066 93.3583 7.56697 93.495 6.68829 93.5731C6.64924 93.5731 6.59066 93.5926 6.55161 93.5926C5.55577 93.6903 4.55992 93.7488 3.56408 93.7488C2.29487 93.7488 1.16235 92.9873 0.674188 91.8157C0.186031 90.6442 0.459399 89.3164 1.33808 88.4182C2.13866 87.5981 2.86113 86.7194 3.54456 85.7821C3.8765 85.333 4.18892 84.8839 4.48182 84.4348C4.50134 84.3957 4.52087 84.3762 4.5404 84.3372H4.59898Z" />
                    </svg>
                )
            }

            {
                // Facebook
                (version === 'facebook') && (
                    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M91.0591 0H9.80908C4.65283 0 0.434082 4.21875 0.434082 9.375V90.625C0.434082 95.7812 4.65283 100 9.80908 100H50.4341V56.25H37.9341V43.75H50.4341V37.5C50.4341 27.168 58.8521 18.75 69.1841 18.75H81.6841V31.25H69.1841C65.7466 31.25 62.9341 34.0625 62.9341 37.5V43.75H81.6841L78.5591 56.25H62.9341V100H91.0591C96.2153 100 100.434 95.7812 100.434 90.625V9.375C100.434 4.21875 96.2153 0 91.0591 0Z" />
                    </svg>
                )
            }

            {
                // Instagram
                (version === 'instagram') && (
                    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M50.4452 24.8795C36.2567 24.8795 24.8122 36.0951 24.8122 49.9998C24.8122 63.9046 36.2567 75.1202 50.4452 75.1202C64.6337 75.1202 76.0783 63.9046 76.0783 49.9998C76.0783 36.0951 64.6337 24.8795 50.4452 24.8795ZM50.4452 66.3313C41.2762 66.3313 33.7804 59.0073 33.7804 49.9998C33.7804 40.9923 41.2539 33.6683 50.4452 33.6683C59.6365 33.6683 67.11 40.9923 67.11 49.9998C67.11 59.0073 59.6142 66.3313 50.4452 66.3313ZM83.1056 23.8519C83.1056 27.1095 80.4285 29.7111 77.1268 29.7111C73.8027 29.7111 71.148 27.0876 71.148 23.8519C71.148 20.6162 73.8251 17.9927 77.1268 17.9927C80.4285 17.9927 83.1056 20.6162 83.1056 23.8519ZM100.083 29.7986C99.7035 21.9498 97.8741 14.9975 92.0069 9.26941C86.1619 3.54136 79.0677 1.74861 71.0587 1.35508C62.8044 0.895962 38.0638 0.895962 29.8094 1.35508C21.8228 1.72675 14.7286 3.5195 8.8613 9.24755C2.99404 14.9756 1.18701 21.928 0.785448 29.7767C0.31696 37.866 0.31696 62.1118 0.785448 70.201C1.1647 78.0498 2.99404 85.0022 8.8613 90.7302C14.7286 96.4583 21.8005 98.251 29.8094 98.6445C38.0638 99.1037 62.8044 99.1037 71.0587 98.6445C79.0677 98.2729 86.1619 96.4801 92.0069 90.7302C97.8518 85.0022 99.6812 78.0498 100.083 70.201C100.551 62.1118 100.551 37.8878 100.083 29.7986ZM89.419 78.8806C87.6789 83.1657 84.3103 86.467 79.9154 88.1941C73.3343 90.7521 57.718 90.1618 50.4452 90.1618C43.1725 90.1618 27.5339 90.7302 20.9751 88.1941C16.6025 86.4888 13.2339 83.1875 11.4715 78.8806C8.8613 72.431 9.46364 57.1271 9.46364 49.9998C9.46364 42.8725 8.88361 27.5467 11.4715 21.1191C13.2116 16.8339 16.5802 13.5327 20.9751 11.8055C27.5562 9.24755 43.1725 9.83785 50.4452 9.83785C57.718 9.83785 73.3566 9.26941 79.9154 11.8055C84.288 13.5108 87.6566 16.8121 89.419 21.1191C92.0292 27.5686 91.4268 42.8725 91.4268 49.9998C91.4268 57.1271 92.0292 72.4529 89.419 78.8806Z" />
                    </svg>
                )
            }

            {
                // LinkedIn
                (version === 'linkedin') && (
                    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M93.1799 0H7.66601C3.74764 0 0.563965 3.23661 0.563965 7.20982V92.7902C0.563965 96.7634 3.74764 100 7.66601 100H93.1799C97.0983 100 100.304 96.7634 100.304 92.7902V7.20982C100.304 3.23661 97.0983 0 93.1799 0ZM30.7087 85.7143H15.9257V37.9911H30.7309V85.7143H30.7087ZM23.3172 31.4732C18.5751 31.4732 14.7458 27.6116 14.7458 22.8795C14.7458 18.1473 18.5751 14.2857 23.3172 14.2857C28.0371 14.2857 31.8886 18.1473 31.8886 22.8795C31.8886 27.6339 28.0593 31.4732 23.3172 31.4732ZM86.1224 85.7143H71.3395V62.5C71.3395 56.9643 71.2282 49.8438 63.6586 49.8438C55.9554 49.8438 54.7755 55.8705 54.7755 62.0982V85.7143H39.9925V37.9911H54.1744V44.5089H54.3747C56.3562 40.7589 61.1873 36.808 68.3784 36.808C83.3395 36.808 86.1224 46.6964 86.1224 59.5536V85.7143Z" />
                    </svg>
                )
            }

            {
                // Menu
                (version === 'menu') && (
                    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.3773 76.3566C16.3773 74.6441 17.7656 73.2559 19.4781 73.2559H83.044C84.7565 73.2559 86.1448 74.6441 86.1448 76.3566V76.3566C86.1448 84.9192 79.2034 91.8605 70.6409 91.8605H31.8812C23.3186 91.8605 16.3773 84.9192 16.3773 76.3566V76.3566Z" />
                        <rect x="0.873413" y="40.6978" width="100" height="18.6047" rx="9.30233" />
                        <path d="M16.3773 23.6435C16.3773 15.081 23.3186 8.13965 31.8812 8.13965H70.6409C79.2034 8.13965 86.1448 15.081 86.1448 23.6435V23.6435C86.1448 25.356 84.7565 26.7443 83.044 26.7443H19.4781C17.7656 26.7443 16.3773 25.356 16.3773 23.6435V23.6435Z" />
                    </svg>
                )
            }

            {
                // Close
                (version === 'close') && (
                    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M97.914 17.0779C101.818 13.1735 101.818 6.83273 97.914 2.92831C94.0096 -0.976105 87.6688 -0.976105 83.7644 2.92831L50.8735 35.8504L17.9515 2.95955C14.047 -0.94487 7.70627 -0.94487 3.80185 2.95955C-0.10257 6.86397 -0.10257 13.2047 3.80185 17.1092L36.7239 50L3.83309 82.9221C-0.0713336 86.8265 -0.0713336 93.1673 3.83309 97.0717C7.73751 100.976 14.0783 100.976 17.9827 97.0717L50.8735 64.1496L83.7956 97.0404C87.7 100.945 94.0408 100.945 97.9452 97.0404C101.85 93.136 101.85 86.7952 97.9452 82.8908L65.0231 50L97.914 17.0779Z" />
                    </svg>
                )
            }

            {
                // Expand
                (version === 'expand') && (
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="m11.64,13.07H1.43c-.38,0-.74-.15-1.01-.42-.27-.26-.42-.63-.42-1.01V1.43c0-.38.15-.74.42-1.01.27-.27.63-.42,1.01-.42h4.18c.28,0,.5.22.5.5s-.22.5-.5.5H1.43c-.11,0-.22.04-.3.12s-.13.19-.13.3v10.21c0,.11.05.22.12.3.08.08.19.13.3.13h10.21c.11,0,.22-.05.3-.13s.13-.19.13-.3v-4.18c0-.28.22-.5.5-.5s.5.22.5.5v4.18c0,.38-.15.74-.42,1.01s-.63.42-1.01.42Z" />
                        <path d="m12.57,4.25c-.28,0-.5-.22-.5-.5V1h-2.75c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h3.25c.28,0,.5.22.5.5v3.25c0,.28-.22.5-.5.5Z" />
                        <path d="m6.54,7.04c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71L12.22.15c.2-.2.51-.2.71,0s.2.51,0,.71l-6.04,6.04c-.1.1-.23.15-.35.15Z" />
                    </svg>
                )
            }
        </span>
    )
}

export default Icon