import { React } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import './Mentions.scss'

import { useDocumentTitle, useDocumentConfig } from 'hooks/useDocument'

const Mentions = () => {
    useDocumentTitle(`Mentions légales`)
    useDocumentConfig(`blue`, true)

    return (
        <motion.main
            className="mentions"
            initial={{ opacity: 0, transition: { duration: 0.3 } }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
            <h1>Mentions légales</h1>

            <h2>Identification</h2>
            <p className="light">
                BIG et Nouf est une <abbr title="Société À Responsabilités Limitées">SARL</abbr> au capital social de 2&nbsp;000&nbsp;&euro;.<br />
                SIRET&nbsp;: 97842850600023<br />
                TVA intercommunautaire&nbsp;: FR55978428506<br />
                Marque déposée <abbr title="Institut National de la Propriété Industrielle">INPI</abbr>&nbsp;: 4983611 
                
            </p>

            <address>
                Siège social&nbsp;:<br />
                BIG et Nouf<br />
                17 Boulevard de Strasbourg<br />
                62000 Arras<br />
                France
            </address>

            <p className="light">
                <Link className="link" to="mailto:contact@bigetnouf.fr">contact@bigetnouf.fr</Link><br />
                <Link className="link" to="tel:+33695179184">06&nbsp;95&nbsp;17&nbsp;91&nbsp;84</Link>
            </p>


            <h2>Responsables de la publication / Gérants</h2>
            <ul className="list no-prefix">
                <li>Marion Dournel</li>
                <li>François Beaumont</li>
            </ul>


            <h2>Hébergement</h2>

            <address>
                o2switch<br />
                Chemin des Pardiaux<br />
                63000 Clermont-Ferrand<br />
                France
            </address>

            <p className="light">
                <Link className="link" to="tel:+33444446040">04&nbsp;44&nbsp;44&nbsp;60&nbsp;40</Link>
            </p>
        </motion.main>
    )
}

export default Mentions