import React from 'react'
import { Link } from 'react-router-dom'

import './Footer.scss';

import Icon from 'components/Icon'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__wrapper columns valign-top">
                <div className="footer__links column-half align-left mobile-margin-bottom">
                    <h3>Liens utiles</h3>
                    <ul className="no-prefix">
                        {/* <li><p className="light"><Link className="link" to={`${process.env.PUBLIC_URL}/articles`}>Nos articles</Link></p></li> */}
                        <li><p className="light"><Link className="link" to={`${process.env.PUBLIC_URL}/mentions`}>Mentions légales</Link></p></li>
                        {/* <li><p className="light"><Link className="link" to={`${process.env.PUBLIC_URL}/faq`}>Questions fréquentes</Link></p></li> */}
                        {/* <li><p className="light"><Link className="link" to={`${process.env.PUBLIC_URL}/styleguide`}>Styleguide</Link></p></li> */}
                    </ul>
                </div>
                <div className="footer__contact column-half align-left">
                    <h3>Pour nous suivre, c'est&nbsp;par&nbsp;ici&nbsp;!</h3>
                    <p className="mail"><Link className="link" to="mailto:contact@bigetnouf.fr">contact@bigetnouf.fr</Link></p>
                    <p>
                        <Link to="https://www.facebook.com/big.et.nouf/" className="social link__icon" title="Facebook"><Icon version="facebook" /></Link>
                        <Link to="https://www.instagram.com/big.et.nouf/" className="social link__icon" title="Instagram"><Icon version="instagram" /></Link>
                        <Link to="https://www.linkedin.com/company/big-et-nouf/" className="social link__icon" title="LinkedIn"><Icon version="linkedin" /></Link>
                    </p>
                </div>
                <div className="footer__mentions column-full align-left">
                    <p className="light">BIG et Nouf &copy; 2023-2024</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer