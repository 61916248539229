import { React } from 'react'
import { motion } from 'framer-motion'

import './Error.scss'

import { useDocumentTitle, useDocumentConfig } from 'hooks/useDocument'

import Hero from 'modules/Hero'
import Button from 'components/Button'

const Error = () => {
    useDocumentTitle(`Oops !`)
    useDocumentConfig(`orange`, false)

    return (
        <motion.main
            className="error"
            initial={{ opacity: 0, transition: { duration: 0.3 } }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
            <Hero scene={1}>
                Cette page<br />
                n'existe pas...
            </Hero>
            
            <Button to={`${process.env.PUBLIC_URL}/`} version="1" size="small">Revenir à l'accueil</Button>
        </motion.main>
    )
}

export default Error