import { lazy } from 'react'

import Homepage from 'pages/Homepage'
import Agency from 'pages/Agency'
import Realisations from 'pages/Realisations'
import Realisation from 'pages/Realisation'
import Services from 'pages/Services'
import Contact from 'pages/Contact'
import Mentions from 'pages/Mentions'
//import Articles from 'pages/Articles'
//import Article from 'pages/Article'
import Error from 'pages/Error'

const Styleguide = lazy(() => import('pages/Styleguide'))
//const FFMPEG = lazy(() => import('pages/Tools/FFMPEG'))

const routes = [
    {
        path: `${process.env.PUBLIC_URL}/`,
        element: <Homepage />,
        exact: true
    },
    {
        path: `${process.env.PUBLIC_URL}/notre-agence`,
        element: <Agency />,
        exact: true
    },
    {
        path: `${process.env.PUBLIC_URL}/realisations-sur-mesure`,
        element: <Realisations />,
        exact: true
    },
    {
        path: `${process.env.PUBLIC_URL}/realisations/:id`,
        element: <Realisation />,
        exact: true
    },
    {
        path: `${process.env.PUBLIC_URL}/nos-services`,
        element: <Services />,
        exact: true
    },
    {
        path: `${process.env.PUBLIC_URL}/nous-contacter`,
        element: <Contact />,
        exact: true
    },
    {
        path: `${process.env.PUBLIC_URL}/mentions`,
        element: <Mentions />,
        exact: true
    },
    /*{
        path: `${process.env.PUBLIC_URL}/articles`,
        element: <Articles />,
        exact: true
    },
    {
        path: `${process.env.PUBLIC_URL}/articles/:id`,
        element: <Article />,
        exact: true
    },*/
    {
        path: `${process.env.PUBLIC_URL}/styleguide`,
        element: <Styleguide />,
        exact: true
    },
    /*{
        path: `${process.env.PUBLIC_URL}/tools/ffmpeg`,
        element: <FFMPEG />,
        exact: true
    },*/
    {
        path: '*',
        element: <Error />,
    }
]

export default routes