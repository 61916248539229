import { React, useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'

// https://greensock.com/react-basics
// https://greensock.com/react-advanced

const HeroScene05 = (props) => {
    const scene = useRef()
    const tl = useRef()

    useLayoutEffect(() => {
        gsap.context(() => {
            tl.current = gsap
                .timeline()
                .to('.dashLeft',  { opacity: 1 - ( 1 * props.ratio), x: -1500 * props.ratio }, 0.00)
                .to('.dashRight', { opacity: 1 - ( 1 * props.ratio), x: 1500 * props.ratio }, 0.00)
                .to('.letterB',   { opacity: 1 - ( 1 * props.ratio), x: -1500 * props.ratio }, 0.10)
                .to('.letterG',   { opacity: 1 - ( 1 * props.ratio), x: 1500 * props.ratio }, 0.10)
                .to('.letterL',   { opacity: 1 - ( 1 * props.ratio), x: -2000 * props.ratio }, 0.15)
                .to('.letterO',   { opacity: 1 - ( 1 * props.ratio), x: 2000 * props.ratio }, 0.15)
        }, scene)
    }, [props.ratio])

    return (
        <svg ref={scene} className="hero__scene hero__scene_large" width="1920" height="1082" viewBox="0 0 1920 1082" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path className="letter letterL" strokeWidth="14" d="M625.5,677.2v7h7h237.1h7v-7v-72.1v-7h-7h-144V297.7v-7h-7h-86.2h-7v7v379.6L625.5,677.2L625.5,677.2z"/>
                <path className="letter letterO" strokeWidth="14" d="M1254.6,483.5c0-53.7-16.3-103.5-48.1-139.9c-31.9-36.5-78.9-59.1-139.1-59.1c-57.9,0-106,22-139.6,59 c-33.5,37-52.2,88.4-52.2,146.8c0,55.5,16.9,105.5,48.8,141.8c32,36.4,78.6,58.4,136.8,58.4c57.1,0,105.7-19.5,139.9-55.5 C1235.3,599,1254.6,547.2,1254.6,483.5z M980.3,488.5c0-35.9,8.2-66.7,22.9-88.2c14.5-21.3,35.4-33.8,61.9-33.8 c26.9,0,47.8,13.2,62.3,34.7c14.6,21.7,22.5,51.9,22.5,85c0,36-8.1,66.7-22.7,88.2c-14.4,21.3-35.1,33.7-61.6,33.7 c-26.3,0-47.3-11.9-62-32.5C988.8,554.9,980.3,524.8,980.3,488.5z"/>
                <path className="letter letterB" strokeWidth="14" d="M289.8,675v6l6,0.9c19.5,2.9,51.4,5.7,94,5.7c75.9,0,124.1-12.4,153.6-35.9l0,0l0,0c25.1-20.3,42-49.2,42-86 c0-46.5-27-79.1-64.7-94.8c34.9-18.2,51.8-48.4,51.8-80.9c0-37.2-20.8-65.3-49.2-80.4c-29.2-17.3-62.9-22.3-115.5-22.3 c-43,0-89.1,3.4-112.3,8l-5.6,1.1v5.7v372.9L289.8,675L289.8,675z M410.1,440.5h-21.2v-75.3c6.7-0.8,15.9-1.4,29.6-1.4 c18.7,0,32.2,3.7,40.9,9.9c8.3,6,12.9,14.7,12.9,27c0,11.8-4.9,21.3-14.8,28.2C447.5,436,431.8,440.5,410.1,440.5z M415.1,610 c-11.2,0-19.5,0-26.2-0.5v-93.7h22.3c20.8,0,38.6,3.7,51,11.4c12,7.4,19.2,18.5,19.2,35.2c0,17.6-7.4,29-18.8,36.4 C450.6,606.5,433.9,610,415.1,610z"/>
                <path className="letter letterG" strokeWidth="14" d="M1630.2,459.9v-7h-7h-141.4h-7v7v67v7h7h51.6v67.6c-2.5,0.7-5.9,1.4-10.2,2c-6.9,0.9-15.4,1.5-25,1.5 c-33.7,0-61.6-11-81.1-30.8s-31.3-49-31.3-86.7c0-39.5,12.9-68.2,33.7-87c20.9-19,50.4-28.7,84.9-28.7c40.7,0,65.7,7.1,85.7,15.8 l7.5,3.2l2.1-7.9l18-68.7l1.5-5.6l-5.2-2.5c-19.8-9.6-58.6-19.3-108.4-19.3c-63.5,0-119.4,18.4-159.5,53.6 c-40.2,35.2-64,86.8-64.3,151.7c-0.6,57.3,19.1,108.9,55.7,143.1c36.6,35.4,88.7,53.2,159.2,53.2c51.1,0,102-12.6,129-21.8l4.7-1.6 v-5V459.9H1630.2z"/>
                <rect className="dash dashLeft" x="287" y="748.5" width="673" height="47" />
                <rect className="dash dashRight" x="960" y="748.5" width="673" height="47" />
            </g>
        </svg>
    )
}

export default HeroScene05