import { React, useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'

// https://greensock.com/react-basics
// https://greensock.com/react-advanced

const HeroScene05 = (props) => {
    const scene = useRef()
    const tl = useRef()

    useLayoutEffect(() => {
        gsap.context(() => {
            tl.current = gsap
                .timeline()
                .to('.shape2', { x: -1000 * props.ratio }, 0.00)
                .to('.shape1', { x: -1000 * props.ratio }, 0.10)
                .to('.shape0', { x: -1000 * props.ratio }, 0.15)
        }, scene)
    }, [props.ratio])

    return (
        <svg ref={scene} className="hero__scene" width="1920" height="1082" viewBox="0 0 1920 1082" fill="none" xmlns="http://www.w3.org/2000/svg">
            {
                Array.from({ length: 3 }, (_, i) => {
                    return (
                        <g key={i} transform={`translate(${i * -81} 0)`}>
                            <path className={`shape shape${i}`} d="M844.994 827.333C844.994 937.209 810.797 1028.27 742.402 1100.52C664.512 1183.62 527.722 1225.12 332.033 1225C317.135 1225 295.72 1224.71 267.787 1224.13H204.473C-14.9652 1224.13 -145.502 1219.66 -187.136 1210.7C-204.379 1207.1 -213 1197.87 -213 1183C-213 1119.08 -209.287 1022.34 -201.861 892.774C-194.436 763.208 -190.711 666.177 -190.688 601.682C-190.688 532.433 -194.263 402.255 -201.413 211.148C-203.183 162.189 -206.448 88.7377 -211.207 -9.20461L-212.103 -21.7388C-212.724 -33.0727 -203.356 -39.9977 -183.998 -42.5137C-164.641 -45.0298 -95.8091 -46.184 22.4968 -45.9762C212.186 -45.9762 323.101 -45.3761 355.241 -44.1757C501.549 -38.7973 609.486 -14.9177 679.053 27.4632C771.243 84.2249 817.338 180.02 817.338 314.85C817.338 350.098 806.636 387.412 785.232 426.792C768.202 457.044 747.856 485.289 724.573 511C702.572 535.491 691.571 546.837 691.571 545.036C691.571 549.226 699.261 555.804 714.745 564.738C756.38 588.629 789.094 626.555 812.889 678.515C834.327 725.158 845.287 775.964 844.994 827.333ZM461.42 323.056C461.42 271.719 435.843 237.682 384.691 220.947C356.138 211.991 309.158 207.513 243.751 207.513C179.518 207.513 147.401 212.291 147.401 221.847C147.401 233.181 146.355 250.344 144.263 273.335C142.171 296.325 141.136 313.488 141.159 324.822C141.159 384.561 146.217 418.032 156.332 425.234C162.862 430.589 200.622 433.278 269.615 433.302C397.485 433.232 461.42 396.484 461.42 323.056ZM488.18 823.732C488.18 769.994 465.868 733.569 421.245 714.456C392.737 702.499 346.355 696.532 282.099 696.555C205.381 696.555 163.747 699.394 157.195 705.072C150.642 710.751 147.378 747.326 147.401 814.799C147.401 885.272 151.263 924.225 158.988 931.658C166.712 939.09 209.531 942.83 287.444 942.876C351.677 942.876 396.876 937.798 423.038 927.641C466.443 910.329 488.157 875.692 488.18 823.732Z" />
                        </g>
                    )
                })
            }
        </svg>
    )
}

export default HeroScene05