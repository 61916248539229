import { React } from 'react'

const FormatedDate = ({ date }) => {
	const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
	const d = new Date(date)
	const day = (d.getDate() === 1) ? (<>1<sup>er</sup></>) : d.getDate()
	const month = months[d.getMonth()]
	const year = d.getFullYear()

	return (
		<span className='date'>
			{ day } { month } { year }
		</span>
	)
}

export default FormatedDate
