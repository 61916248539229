import { React, useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'

// https://greensock.com/react-basics
// https://greensock.com/react-advanced

const HeroScene03 = (props) => {
    const scene = useRef()
    const tl = useRef()

    useLayoutEffect(() => {
        gsap.context(() => {
            tl.current = gsap
                .timeline()
                .to('.shape2', { x: -1000 * props.ratio }, 0.00)
                .to('.shape1', { x: -1000 * props.ratio }, 0.10)
                .to('.shape0', { x: -1000 * props.ratio }, 0.15)
        }, scene)
    }, [props.ratio])

    return (
        <svg ref={scene} className="hero__scene" width="1920" height="1080" viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
            {
                Array.from({ length: 3 }, (_, i) => {
                    return (
                        <g key={i} transform={`translate(${i * -140} 0)`}>
                            <path className={`shape shape${i}`} d="M960 622C960 1201.9 489.899 1672 -90 1672C-669.899 1672 -1140 1201.9 -1140 622C-1140 42.101 -669.899 -428 -90 -428C489.899 -428 960 42.101 960 622Z" />
                        </g>
                    )
                })
            }
        </svg>
    )
}

export default HeroScene03