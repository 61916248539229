import { React } from 'react'
import { motion } from 'framer-motion'

import './Agency.scss'

import { useDocumentTitle, useDocumentConfig } from 'hooks/useDocument'

import Hero from 'modules/Hero'
import Button from 'components/Button'
import Picto from 'components/Picto'

import Arras from '../images/city_arras.jpg'
import Francois from '../images/profil_francois.jpg'
import Marion from '../images/profil_marion.jpg'

const Agency = () => {
	useDocumentTitle(`À propos`)
	useDocumentConfig(`orange`, false)

	return (
		<motion.main
			className='agency'
			initial={{ opacity: 0, transition: { duration: 0.3 } }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 0.3 } }}
		>
			<Hero
				scene={2}
				scrollTo='Savoir qui se cache derrière BIG et Nouf'
			>
				À propos
			</Hero>

			<section className='agency-presentation columns'>
				<div className='column-half'>
					<h1>Votre agence web à Arras, à proximité de Lille, au coeur de la région Hauts-de-France</h1>
					<p>
						Votre partenaire de confiance pour la création de sites web et d'applications sur mesure, la TMA, le développement sur divers CMS, et bien plus encore. Nous prenons plaisir à fournir des solutions web exceptionnelles pour répondre à vos besoins.
					</p>
					<span className='picto-container mobile-margin-bottom'>
						<Picto
							version='target'
							size='small'
						/>
					</span>
					<Button
						to={`${process.env.PUBLIC_URL}/nous-contacter`}
						version='2'
					>
						Contactez-nous
					</Button>
				</div>
				<div className='column-half align-right mobile-hide'>
					<picture>
						<img
							src={Arras}
							alt=''
						/>
					</picture>
				</div>
			</section>

			<section className='agency-mdournel columns'>
				<div className='column-half align-left mobile-align-center mobile-second'>
					<picture>
						<img
							src={Marion}
							alt=''
						/>
					</picture>
				</div>
				<div className='column-half align-left mobile-margin-bottom mobile-first'>
					<h2>Devéloppeuse créative à l’imagination fertile</h2>
					<p>D'une nature très sociale et curieuse, j'aime découvrir et travailler sur vos business et entreprises. Je cherche toujours à aller dans le pourquoi de chaque projet, là où il fait le plus sens et fera vibrer vos clients.</p>
					<p>A l'écoute et très créative, je suis aussi une rêveuse avec une sensibilité particulière aux porteurs de projets artistiques et tournés vers l'humain.</p>
					<h4>Marion alias “Nouf”, co-gérante</h4>
				</div>
			</section>

			<section className='agency-fbeaumont columns align-left'>
				<div className='column-half align-left mobile-margin-bottom mobile-first'>
					<h2>Développeur senior à l’esprit vif et structuré </h2>
					<p>En tant que développeur senior, ma passion pour le développement front-end se reflète dans chaque projet. Je porte une attention minutieuse aux détails et prends plaisir à développer des web apps interactives. Je suis toujours prêt à résoudre n'importe quel défi technique.</p>
					<p>Adaptable et très réactif, je suis également très intéressé par les modèles d'intelligence artificielle et génératives.</p>
					<h4>François alias “BIG”, co-gérant</h4>
				</div>
				<div className='column-half align-right mobile-align-center mobile-second'>
					<picture>
						<img
							src={Francois}
							alt=''
						/>
					</picture>
				</div>
			</section>
		</motion.main>
	)
}

export default Agency
