import { React, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import './Header.scss';

import Logo from 'components/Logo'
import Icon from 'components/Icon'

const Header = () => {
    const location = useLocation()

    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const [hidden, setHidden] = useState(true)
    const [opacity, setOpacity] = useState(1)
    const [menuOpen, setMenuOpen] = useState(false)

    window.addEventListener('scroll', e => {
        const ratio = Math.min(1, Math.round((window.scrollY / (window.innerHeight / 2)) * 100) / 100)
        //setOpacity(ratio)
        setOpacity(1)
        setHidden(ratio === 0)
    })

    window.addEventListener('resize', e => {
        setInnerWidth(window.innerWidth)
    })

    function testLocation(locations) {
        let isCurrent = false

        locations.forEach(testLocation => {
            if (
                (location.pathname.startsWith(`${process.env.PUBLIC_URL}${testLocation}`)) ||
                (location.pathname === `${process.env.PUBLIC_URL}${testLocation}`)
            ) {
                isCurrent = true
            }
        })

        return isCurrent ? 'header__link header__link-current' : 'header__link'
    }

    function setMenuState() {
        setMenuOpen(!menuOpen)
    }

    function navClickHandler() {
        setMenuOpen(false)
    }

    return (
        <>
            <header style={{ opacity: opacity }} className={`header ${hidden ? 'hidden' : ''} ${menuOpen ? 'mobile-open' : ''}`}>
                <Link to={`${process.env.PUBLIC_URL}/`} className="header__title">
                    { (innerWidth >= 870) && (<Logo version="1" size="smaller" complete={true} aligned={true} />) }
                    { (innerWidth < 870) && (<Logo version="1" size="smaller" complete={false} aligned={false} />) }
                </Link>
                <nav className="header__nav">
                    <ol className="header__list no-prefix">
                        <li className="header__item"><Link to={`${process.env.PUBLIC_URL}/notre-agence`} className={testLocation(['/notre-agence'])} onClick={navClickHandler}>L'agence</Link></li>
                        <li className="header__item"><Link to={`${process.env.PUBLIC_URL}/nos-services`} className={testLocation(['/nos-services'])} onClick={navClickHandler}>Nos services</Link></li>
                        <li className="header__item"><Link to={`${process.env.PUBLIC_URL}/realisations-sur-mesure`} className={testLocation(['/realisations-sur-mesure', '/realisations/'])} onClick={navClickHandler}>Nos réalisations</Link></li>
                        <li className="header__item"><Link to={`${process.env.PUBLIC_URL}/nous-contacter`} className={testLocation(['/nous-contacter'])} onClick={navClickHandler}>Contact</Link></li>
                    </ol>
                </nav>
                {
                    (innerWidth < 640) && (
                        <button onClick={setMenuState} className="header__mobile_button">
                            <Icon version={(menuOpen) ? 'close' : 'menu'} size="small" />
                        </button>
                    )
                }
            </header>
            <span className="header__overlay" onClick={navClickHandler} />
        </>
    )
}

export default Header