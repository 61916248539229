import { useEffect } from 'react'

export function useDocumentTitle(pageTitle) {
    useEffect(() => {
        const prefix = (pageTitle !== undefined) ? `${pageTitle } — ` : ``
        const title = `${prefix}BIG et Nouf – Conception de sites web et d'applications interactives`

		document.title = title
		document.querySelector('meta[property="og:title"]').content = title
    }, [pageTitle])
}

export function useDocumentConfig(pageColor = 'blue', alwaysVisibleHeader = false) {
    useEffect(() => {
        // Color
        if (pageColor === 'orange') {
		    document.body.classList.remove('is-white')
            document.body.classList.add('is-orange')
        }
        else if (pageColor === 'white') {
		    document.body.classList.add('is-white')
            document.body.classList.remove('is-orange')
        }
        else {
            document.body.classList.remove('is-white')
            document.body.classList.remove('is-orange')
        }
        
        // Header visibility
        if (alwaysVisibleHeader === true) {
            document.body.classList.add('header-always-visible')
        }
        else {
            document.body.classList.remove('header-always-visible')
        }
	})
}