import { useEffect, useState } from 'react'

export function useFetch(url, type) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        const path = `${process.env.PUBLIC_URL}${url}`

        // JSON
        if (type === 'json') {
            fetch(path, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Cache-Control': 'no-cache' } })
                .then(res => res.json())
                .then(json => {
                    setData(json)
                }).catch(e => {
                    setErrors(e)
                }).finally(() => {
                    setLoading(false)
                })
        }
        
        // Markdown
        else if (type === 'markdown') {
            fetch(path, { headers: { 'Content-Type': 'text/markdown', 'Accept': 'text/markdown', 'Cache-Control': 'no-cache' } })
                .then(res => res.text())
                .then(text => {
                    setData(text)
                }).catch(e => {
                    setErrors(e)
                }).finally(() => {
                    setLoading(false)
                })
        }
    }, [url, type])

    return {
        loading,
        data,
        errors
    }
}