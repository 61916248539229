import { React } from 'react'
import { Link } from 'react-router-dom'

import Logo from 'components/Logo'
import Tag from 'components/Tag'
import FormatedDate from 'components/FormatedDate'

import './Card.scss'

const Card = props => {
	const ext = props.thumbnail.slice(-3)
	const path = props.thumbnail.replace(`.${ext}`, '')

	return (
		<div
			className={`card ${props.className || ''}`}
			tabIndex='-1'
		>
			<Link
				className='card__visuel'
				to={`${process.env.PUBLIC_URL}/${props.type === 'realisation' ? 'realisations' : 'articles'}/${props.id}`}
				tabIndex="-1"
			>
				{props.thumbnail && (
					<picture className='card__thumbnail'>
						<source srcSet={`${process.env.PUBLIC_URL}${path}.webp`} type='image/webp' />
						<img src={`${process.env.PUBLIC_URL}${path}.${ext}`} alt='' />
					</picture>
				)}
				{!props.thumbnail && (
					<div className='card__thumbnail'>
						<Logo
							version='3'
							size='huge'
						/>
					</div>
				)}

				<ul className='card__taglist no-prefix'>
					{props.tags &&
						props.tags.map((tag, i) => (
							<li key={i}>
								<Tag type={tag.type}>{tag.content}</Tag>
							</li>
						))}
				</ul>
			</Link>
			<div className='card__description' >
				<p className='title'>{props.title}</p>
				
				{ props.date && (<FormatedDate date={props.date} />) }
				{props.description && <p className='light' dangerouslySetInnerHTML={{__html: props.description}}></p>}

				<Link
					className='link__underline'
					to={`${process.env.PUBLIC_URL}/${props.type === 'realisation' ? 'realisations' : 'articles'}/${props.id}`}
				>
					{props.type === 'realisation' ? 'En savoir plus' : 'Lire la suite'}
				</Link>
			</div>
		</div>
	)
}

export default Card
