import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import routes from './routes'

const Router = () => {
  const location = useLocation()

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Routes>
    </AnimatePresence>
  )
}

export default Router