import React from 'react'
import { Link } from 'react-router-dom'

import './Button.scss'

const Button = (props) => {
    const isLink = (props.to !== undefined) ? props.to : false
    const version = (props.version !== undefined) ? props.version : 1
    const size = (props.size !== undefined) ? props.size : 'small'

    return (
        isLink ? (
            <Link to={props.to} className={`button v${version} ${size} ${props.className || ''}`}>
                {props.children}
            </Link>
        ) : (
            <button onClick={props.myClick} className={`button v${version} ${size} ${props.className || ''}`}>
                {props.children}
            </button>
        )
    )
}

export default Button