import { React, useState, useEffect, lazy, Suspense } from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import './Realisation.scss'

import { useDocumentTitle, useDocumentConfig } from 'hooks/useDocument'
import { useFetch } from 'hooks/useFetch'

import Loader from 'components/Loader'
import Tag from 'components/Tag'
import Card from 'components/Card'
import Button from 'components/Button'

//import Markdown from 'components/Markdown'
const Markdown = lazy(() => import('components/Markdown'))

const Realisation = () => {
	const [title, setTitle] = useState(undefined)
	const { id } = useParams()
	const [projectPrev, setProjectPrev] = useState(null)
	const [projectNext, setProjectNext] = useState(null)
	const [tags, setTags] = useState([])
	const [content, setContent] = useState('')
	const { data: jsonData } = useFetch(`/realisations/listing.json`, 'json')
	const { loading, data } = useFetch(`/realisations/${id}/content.md`, 'markdown')

	useDocumentTitle(title)	
	useDocumentConfig(`white`, true)

	useEffect(() => {
		if (data === null) {
			return
		}

		setContent(data)
	}, [data])

	useEffect(() => {
		if (jsonData === null) {
			return
		}

		// Current
		const currentIndex = jsonData.findIndex(elt => elt.id === id)
		const project = jsonData.find(project => project.id === id)

		if (project) {
			setTags(project.tags)
			setTitle(`${project.description} (${project.title})`)

			// Featured
			const prev = currentIndex > 0 ? currentIndex - 1 : jsonData.length - 1
			const next = currentIndex < jsonData.length - 1 ? currentIndex + 1 : 0
			setProjectPrev(jsonData[prev])
			setProjectNext(jsonData[next])
		}
	}, [jsonData, id])

	return (
		<motion.main
			className='realisation'
			initial={{ opacity: 0, transition: { duration: 0.3 } }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 0.3 } }}
		>
			<picture id="project__hero">
				<source srcSet={`/realisations/${id}/hero.webp`} type='image/webp' />
				<img src={`/realisations/${id}/hero.jpg`} alt="Visuel de présentation du projet" />
			</picture>
			{ loading && <Loader /> }
			{ (content !== '') && 
				<Suspense fallback={<Loader />}>
					<div className='realisation_container'>
						<ul
							id='project__taglist'
							className='no-prefix'
						>
							{tags.map((tag, i) => {
								return (
									<li key={i}>
										<Tag type={tag.type}>{tag.content}</Tag>
									</li>
								)
							})}
						</ul>
						
						<Markdown>
							{ content }
						</Markdown>

						<section id='project__more'>
							<div className='project__more_item'>
								<div className='project__more_presentation'>
									<h3>Ces réalisations peuvent également vous intéresser</h3>
									<Button
										to={`${process.env.PUBLIC_URL}/realisations-sur-mesure`}
										version='1'
									>
										Toutes nos réalisations
									</Button>
								</div>
							</div>
							{projectPrev !== null && (
								<div className='project__more_item'>
									<Card
										id={projectPrev.id}
										type='realisation'
										thumbnail={`/realisations/${projectPrev.id}/thumbnail.jpg`}
										title={projectPrev.title}
										description={projectPrev.description}
										tags={projectPrev.tags}
									/>
								</div>
							)}

							{projectNext !== null && (
								<div className='project__more_item'>
									<Card
										id={projectNext.id}
										type='realisation'
										thumbnail={`/realisations/${projectNext.id}/thumbnail.jpg`}
										title={projectNext.title}
										description={projectNext.description}
										tags={projectNext.tags}
									/>
								</div>
							)}
						</section>
					</div>
				</Suspense>
			}
		</motion.main>
	)
}

export default Realisation
