import { React } from 'react'
import { motion } from 'framer-motion'

import './Services.scss'

import { useDocumentTitle, useDocumentConfig } from 'hooks/useDocument'

import Hero from 'modules/Hero'
import Button from 'components/Button'

import illu_dev from '../images/illu_dev.svg'
import illu_tma from '../images/illu_tma.svg'
import illu_wordpress from '../images/illu_wordpress.svg'
import illu_surmesure from '../images/illu_surmesure.svg'

const Services = () => {
	useDocumentTitle(`Offres et services`)
	useDocumentConfig(`blue`, false)

	return (
		<motion.main
			className='services'
			initial={{ opacity: 0, transition: { duration: 0.3 } }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 0.3 } }}
		>
			<Hero
				scene={3}
				scrollTo='Découvrir nos services'
			>
				Offres et
				<br />
				services
			</Hero>

			<div className='columns service valign-center'>
				<div className='column-auto align-right mobile-align-center mobile-second'>
					<picture>
						<img
							className="illustration"
							src={illu_dev}
							alt=''
						/>
					</picture>
				</div>
				<div className='column-auto text align-left mobile-first'>
					<h3>Développement & Intégration</h3>
					<p className='light'>
						Nous sommes là pour répondre à vos besoins sur vos projets numériques, applications, sites e-commerce.<br />
						Nous accompagnons nos clients sur le design et le developpement de leurs projets.
					</p>
				</div>
			</div>

			<div className='columns service valign-center'>
				<div className='column-auto text align-left mobile-first'>
					<h3>
						<abbr title='Tierce Maintenance Applicative'>TMA</abbr>
					</h3>
					<p className='light'>
						Notre agence propose ses services pour maintenir et améliorer les performances de vos applications tout en réduisant vos coûts en libérant des ressources humaines.
					</p>
				</div>
				<div className='column-auto align-left mobile-align-center mobile-second'>
					<picture>
						<img
							className="illustration"
							src={illu_tma}
							alt=''
						/>
					</picture>
				</div>
			</div>

			<div className='columns service valign-center'>
				<div className='column-auto align-right mobile-align-center mobile-second'>
					<picture>
						<img
							className="illustration"
							src={illu_wordpress}
							alt=''
						/>
					</picture>
				</div>
				<div className='column-auto text align-left mobile-first'>
					<h3>Wordpress, Shopify, etc.</h3>
					<p className='light'>
						Nous sommes également à l'écoute de vos besoins en matière de création,
						de refonte ou de personnalisation sur des plateformes CMS telles que Wordpress, Shopify et bien d'autres encore.
					</p>
				</div>
			</div>

			<div className='columns service valign-center'>
				<div className='column-auto text align-left mobile-first'>
					<h3>Site sur mesure</h3>
					<p className='light'>
						Notre spécialité réside dans la création de sites sur mesure, conçus pour renforcer votre visibilité et votre identité visuelle.<br />
						Notre maîtrise du développement web, de la charte graphique et de l'intégration nous permet de matérialiser vos idées avec précision.
					</p>
				</div>
				<div className='column-auto align-left mobile-align-center mobile-second'>
					<picture>
						<img
							className="illustration"
							src={illu_surmesure}
							alt=''
						/>
					</picture>
				</div>
			</div>

			<div className='column-full align-center'>
				<Button
					to={`${process.env.PUBLIC_URL}/nous-contacter`}
					version='1'
				>
					Contactez-nous
				</Button>
			</div>
		</motion.main>
	)
}

export default Services
