import { React } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import './Contact.scss'

import { useDocumentTitle, useDocumentConfig } from 'hooks/useDocument'

import Hero from 'modules/Hero'
import Picto from 'components/Picto'
import Icon from 'components/Icon'

const Contact = () => {
	useDocumentTitle(`Nous contacter`)
	useDocumentConfig(`blue`, false)

	return (
		<motion.main
			className='contact'
			initial={{ opacity: 0, transition: { duration: 0.3 } }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 0.3 } }}
		>
			<Hero
				scene={5}
				scrollTo='Prendre contact'
			>
				Nous<br />
				contacter
			</Hero>
			<section className='columns valign-center'>
				<div className='column-half align-left mobile-align-center mobile-second'>
					<Picto
						version='speaker'
						size='huge'
					/>
				</div>
				<div className='column-half align-left mobile-first mobile-margin-bottom'>
					<h1>Contact</h1>
					<p>Nous nous engageons à répondre à vos demandes dans les plus brefs délais et serons ravis de discuter avec vous de votre projet et d’établir un devis personnalisé en fonction de vos besoins et exigences.</p>
					<p>Nous sommes convaincus que notre collaboration sera fructueuse et nous serons heureux de vous accompagner dans la réalisation de vos projets en ligne.</p>
				</div>
			</section>

			<section className='contact-listing columns valign-center'>
				<div className='column-full align-left'>
					<h1>
						Des idées ? Nous avons les compétences. <br />
						Contactez-nous.
					</h1>
				</div>
				<div className='column-half align-left'>
					<ul className='no-prefix'>
						<li>
							<Icon
								version='comment'
								size='big'
							/>
							<div>
								<h5>Discutons</h5>
								<p className='light'>Envie de nous envoyer un mail directement&nbsp;?</p>
								<p>
									<Link
										className='link__underline'
										to='mailto:contact@bigetnouf.fr'
									>
										contact@bigetnouf.fr
									</Link>
								</p>
							</div>
						</li>
						<li>
							<Icon
								version='mobile'
								size='big'
							/>
							<div>
								<h5>Par téléphone</h5>
								<p className='light'>Du lundi au vendredi de 9h à 18h.</p>
								<p>
									<Link
										className='link__underline'
										to='tel:+33695179184'
									>
										06&nbsp;95&nbsp;17&nbsp;91&nbsp;84
									</Link>
								</p>
							</div>
						</li>
						<li>
							<Icon
								version='link'
								size='big'
							/>
							<div>
								<h5>Nos réseaux sociaux</h5>
								<p className='light'>Nous essayons de poster régulièrement.</p>
								<p>
									<Link
										to='https://www.facebook.com/big.et.nouf/'
										className='social link__icon'
										title='Facebook'
									>
										<Icon
											version='facebook'
											size='smaller'
										/>
									</Link>
									<Link
										to='https://www.instagram.com/big.et.nouf/'
										className='social link__icon'
										title='Instagram'
									>
										<Icon
											version='instagram'
											size='smaller'
										/>
									</Link>
									<Link
										to='https://www.linkedin.com/company/big-et-nouf/'
										className='social link__icon'
										title='LinkedIn'
									>
										<Icon
											version='linkedin'
											size='smaller'
										/>
									</Link>
								</p>
							</div>
						</li>
					</ul>
				</div>
				<div className='column-half align-right mobile-margin-top mobile-align-center'>
					<Picto
						version='contact'
						size='huge'
					/>
				</div>
			</section>
		</motion.main>
	)
}

export default Contact
