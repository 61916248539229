import { React, useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import './Realisations.scss'

import { useDocumentTitle, useDocumentConfig } from 'hooks/useDocument'
import { useFetch } from 'hooks/useFetch'

import Loader from 'components/Loader'
import Hero from 'modules/Hero'
import Button from 'components/Button'
import Picto from 'components/Picto'
import Card from 'components/Card'

const Realisations = () => {
	useDocumentTitle(`Réalisations sur-mesure`)
	useDocumentConfig(`orange`, false)

	const [projects, setProjects] = useState([])
	const { loading, data } = useFetch(`/realisations/listing.json`, 'json')

	useEffect(() => {
		if (data === null) {
			return
		}

		setProjects(data)
	}, [data])

	return (
		<motion.main
			className='realisations'
			initial={{ opacity: 0, transition: { duration: 0.3 } }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 0.3 } }}
		>
			<Hero
				scene={4}
				scrollTo='Voir nos références'
			>
				Réalisations
				<br />
				sur-mesure
			</Hero>

			<section className='realisations-presentation columns valign-center'>
				<div className='column-twothird align-left mobile-margin-bottom'>
					<h1>Quelques références</h1>
					<p>
						Ci-dessous, nous vous présentons une sélection de projets dans lesquels nous avons joué un rôle actif, témoignant ainsi de notre expertise et de notre engagement envers chaque réalisation.
					</p>
				</div>
				<div className='column-third align-right mobile-align-center'>
					<Picto
						version='chess'
						size='big'
					/>
				</div>
			</section>

			<section className='realisations-list columns'>
				{ loading && <Loader /> }
				{
					(projects.length > 0) &&
					projects.map((project, i) => (
						<div
							className='column-third align-center mobile-margin-bottom'
							key={i}
						>
							<Card
								id={project.id}
								type="realisation"
								thumbnail={`/realisations/${project.id}/thumbnail.jpg`}
								title={project.title}
								description={project.description}
								tags={project.tags}
							/>
						</div>
					))
				}

				<div className='column-full align-center'>
					<Button
						to={`${process.env.PUBLIC_URL}/nous-contacter`}
						version='2'
					>
						Contactez-nous
					</Button>
				</div>
			</section>
		</motion.main>
	)
}

export default Realisations
