import React from 'react'

import './Loader.scss';

const Loader = () => {
    return (
        <div className="loader">
            <span className="loader-animation" />
        </div>
    )
}

export default Loader