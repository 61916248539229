import React from 'react'

import './Tag.scss'

const Tag = props => {
	const type = (props.type !== undefined) ? props.type : 'dev'

	return (
		<span className={`tag tag-${type} ${props.className || ''}`}>
			{props.children}
		</span>
	)
}

export default Tag
