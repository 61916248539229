import { React, useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'

// https://greensock.com/react-basics
// https://greensock.com/react-advanced

const HeroScene01 = (props) => {
    const scene = useRef()
    const tl = useRef()

    useLayoutEffect(() => {
        gsap.context(() => {
            tl.current = gsap
                .timeline()
                .to('.wave2', { y: -1000 * props.ratio }, 0.00)
                .to('.wave1bis', { y: -1000 * props.ratio }, 0.10)
                .to('.wave1', { y: -1000 * props.ratio }, 0.15)
                .to('.wave0', { y: -1000 * props.ratio }, 0.20)
        }, scene)
    }, [props.ratio])

    return (
        <svg ref={scene} className="hero__scene hero__scene_large" width="1920" height="1080" viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
            <path className="wave wave0" d="M474.7,388.5c395.9,0,502.3,506.5,926.6,506.5c258.4,0,417.5-159.9,518.7-284.8v-911.1H0V620 C105.7,503,264.7,388.5,474.7,388.5z"/>
            <path className="wave wave1" d="M1920-300.9H0v885.8c113.1-127.8,272.5-250,469-250c411.8,0,518.2,538.8,969.5,518.5 c243.3-10.9,379.9-159.4,481.5-267.5"/>
            <path className="wave wave1bis" d="M0,551.3c110.5-130,274.6-262.4,484.4-262.4c411.7,0,519.4,549,970.6,525.4c228.7-12,362.4-138.7,465-258 v-857.1H0"/>
            <path className="wave wave2" d="M0-300.9v785.4c109.9-137,273.3-276.4,482.9-276.4c411.9,0,526.2,583,977.6,556 c226.3-13.5,356.8-158.9,459.5-294v-771"/>
        </svg>
    )
}

export default HeroScene01