import { React, useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import './Homepage.scss'

import { useDocumentTitle, useDocumentConfig } from 'hooks/useDocument'
import { useFetch } from 'hooks/useFetch'

import Loader from 'components/Loader'
import Hero from 'modules/Hero'
import Button from 'components/Button'
import Picto from 'components/Picto'
import Card from 'components/Card'

const Homepage = () => {
	useDocumentTitle()
	useDocumentConfig(`blue`, false)

	const [projects, setProjects] = useState([])
	const { loading, data } = useFetch(`/realisations/listing.json`, 'json')

	useEffect(() => {
		if (data === null) {
			return
		}

		const filtered = data.filter(project => project.highlight === true)
		const randomized = filtered.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
		setProjects(randomized.slice(0,3))
	}, [data])

	return (
		<motion.main
			className='homepage'
			initial={{ opacity: 0, transition: { duration: 0.3 } }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0, transition: { duration: 0.3 } }}
		>
			<Hero
				scene={1}
				scrollTo="Découvrir l'agence"
			>
				L'agence web
				<br />
				qui voit grand
			</Hero>

			<section className='homepage-agency columns valign-center'>
				<div className='column-half mobile-margin-bottom'>
					<h1>L'agence</h1>
					<p>
						BIG et Nouf conçoit des sites, des applications et des expériences interactives pour dispositifs tactiles.
					</p>
					<Button to={`${process.env.PUBLIC_URL}/notre-agence`}>Nous découvrir</Button>
				</div>
				<div className='column-half align-right mobile-align-center'>
					<Picto
						version='rocket'
						size='big'
					/>
				</div>
			</section>

			<section className='homepage-realisations'>
				<h2>Conception de sites web et bien plus encore</h2>
				<p className='light'>
					Notre gamme de services englobe bien plus que la simple conception de sites web. De la création de sites institutionnels et de plateformes e-commerce,
					à la réalisation de sites web dédiés à la prospection pour les PME, PMI, TPE et associations, nous excellerons également dans le développement de web apps,
					d'applications mobiles, en mettant toujours l'accent sur une expérience utilisateur fluide grâce à des designs responsives.
				</p>

				<ul className='columns homepage-realisations-list no-prefix'>
					{ loading && <Loader /> }
					{ (projects.length > 0) && 
						projects.map((project, i) => (
							<li
								className='column-third align-center mobile-margin-bottom'
								key={i}
							>
								<Card
									id={project.id}
									type="realisation"
									thumbnail={`/realisations/${project.id}/thumbnail.jpg`}
									title={project.title}
									description={project.description}
									tags={project.tags}
								/>
							</li>
						))
					}
				</ul>

				<div className='columns'>
					<div className='column-full align-center'>
						<Button to={`${process.env.PUBLIC_URL}/realisations-sur-mesure`}>Parcourir nos réalisations</Button>
					</div>
				</div>
			</section>
		</motion.main>
	)
}

export default Homepage
